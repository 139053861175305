import { colors } from '../../style/variables'
import styled, { css } from "styled-components";

interface AvatarStyledProps {
    src?: string,
    size?: number,
    isMe?:boolean,
    isEditable?: boolean,
    pointed?: any,
    index?: number,
    badge?: any,
    place?: string,
    facilitator?: boolean,
    hasVoted?: boolean,
    onClick?: boolean,
    imageIsLoading: boolean
}

export const AvatarEggStyled = styled.div<AvatarStyledProps>`
    position:relative;
    text-decoration: none !important;
    .main{
        border-radius:100%;
        background: ${props => props.bgColor ? `${props.bgColor}` : `${colors.lightGreyBg}`};
        overflow:hidden;
        display:flex;
        align-items:center;
        justify-content: center;
        position:relative;
        border:${props => props.src ? "none" : `1px solid ${colors.borders}`};
        width:${props => props.size ? `${props.size}px` : '62px'};
        height:${props => props.size ? `${props.size}px` : '62px'};
        span.gravatar{
                font-size:${props => props.size ? props.size * .305 + 'px' : '15px'};
                font-weight: bold;
                letter-spacing:.05rem;
                /* color:${colors.washedGreyEgg} */
                color:${({ bgColor }) => !bgColor ? '#1F2933' : 'white'};
        }
        ${({ place, size }) => place === 'table' && css`
                width:${size && '55px'};
                height:${size && '55px'};
            @media screen and (min-width:768px){
                width:${size ? `${size}px` : '62px'};
                height:${size ? `${size}px` : '62px'};
            }
        `};
        /* ${({ isMe, size }) => isMe && css`
            border:7px solid ${colors.blueEgg};
            width:${size ? `${size + 5}px` : '67px'};
            height:${size ? `${size + 5}px` : '67px'};
        `} */
        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center center;
        }
        .default{
            // Chequear esto para tamaños más grandes del avatar
            width: 45%;
            font-size: 3rem;
            color:${colors.mediumGreyBg};
        }
        ${props => props.onClick && css`
            cursor:pointer;
            position: relative;
            img{
                filter: brightness(75%);
            }
            &:hover{
                .editButton{
                    visibility: visible;
                }
            }
            .editButton{
                visibility: hidden;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                margin: auto;
                color: white;
                font-size: 2rem;
            }
        `}
        transition: all 0.1s ease-in-out;
        ${props => props.pointed && (props.index === props.pointed) && css`
            width:80px;
            height:80px;
            @media screen and (min-width:768px){
                width:95px;
                height:95px;
            }
        `}
        ${props => props.animate && css`
            animation-duration: 1s;
            animation-iteration-count: infinite;
            transform-origin: bottom;
            &:hover{
                animation-name: grow;
                animation-timing-function: ease;
            }
            @keyframes grow {
                0%   { transform: scale(1); }
                50%  { transform: scale(1.05); }
                100% { transform: scale(1); }
            }
        `}
    }
    // Overlay to upload image
    .overlay{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border-radius: 100%;
        opacity: 0.8;
        display: flex;
        justify-content: center;
        align-items: center;
        color: white;
        text-align: center;
        font-size: 12px;
        border:none;
        margin:0;
        cursor:pointer;
        background-color: rgba(0,0,0,0);
        font-size: 0px;
        &:hover{
            -webkit-transition: background-color 150ms linear;
            -ms-transition: background-color 150ms linear;
            transition: background-color 150ms linear;
            font-size: 12px;
            background-color: rgba(0,0,0,0.7);
        }
    }
    ${props => props.imageIsLoading && css`
        .overlay{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 100%;
            opacity: 0.8;
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
            text-align: center;
            font-size: 12px;
            border:none;
            margin:0;
            font-size: 12px;
            background-color: rgba(0,0,0,0.7);
        }
    `}
    .badge{
        position:absolute;
        bottom:-3px;
        right:-3px;
        // badge size is near half the size of the avatar
        height:${props => props.size ? (`${props.size * .52}px`) : '24px'};
        width:${props => props.size ? (`${props.size * .52}px`) : '24px'};
        // radius should the half of the size
        border-radius:${props => props.size ? (`${props.size / 2}px`) : '26px'};
        display:flex;
        justify-content:center;
        align-items:center;
        // border is around 10% of the avatar size
        border:${props => props.size ? (`${props.size * .08}px`) : '2px'} solid white;
        z-index:4;
        // applies to the badge icon
        .icon{
            font-size:${props => props.size ? (`${props.size * .18}px`) : '0.6rem'};
            color:white;
        }
        &.facilitator{
            background-color:${colors.eggFacilitators};
        }
        &.image-upload{
            background-color:${colors.darkGreyEgg};
        }
        &.isMentor{
            background-color:${colors.eggPurple};
        }
        ${props => props.badge && props.badge[2] === 'tutor' && css`
            background-color:white;
        `}
    }
    .voted{
        position:absolute;
        bottom:-5px;
        right:-5px;
        height:${props => props.size && (props.size * .40 > 28) ? (`${props.size * .40}px`) : '28px'};
        width:${props => props.size && (props.size * .40 > 28) ? (`${props.size * .40}px`) : '28px'};
        /* background:red; */
        display:flex;
        justify-content:center;
        align-items:center;
        .icon{
            font-size:1.4rem;
        }
        .icon path{
            color:${colors.eggColor};
            stroke: white;
            stroke-width: 6rem;
        }
    }
    .tooltip{
        position:absolute;
        top:-37px;
        left:-20px;
        padding:7px 10px;
        min-width:100px;
        background-color:${colors.darkGreyEgg};
        border-radius:7px;
        color:white;
        display:flex;
        align-items:center;
        justify-content:center;
        text-transform:capitalize;
        :after{ /*arrow added to uparrowdiv DIV*/
            content:'';
            display:block;
            position:absolute;
            bottom:-10px; /*should be set to -border-width x 2 */
            left:45%;
            width:0;
            height:0;
            border-color: ${colors.darkGreyEgg} transparent transparent  transparent; /*border color should be same as div div background color*/
            border-style: solid;
            border-width: 5px;
        }
    }
`